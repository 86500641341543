@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Indie+Flower&family=Zen+Loop&family=Zen+Tokyo+Zoo&display=swap');
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.App{
    background-image: url(./background.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
}