@import url(https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Indie+Flower&family=Zen+Loop&family=Zen+Tokyo+Zoo&display=swap);

.header{
    text-decoration: none;
    font-size: 3rem;
    text-transform: uppercase;
    color: cornsilk;
    font-family: 'Zen Loop', cursive;
    font-weight: bold;
}
.header-container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2%;
    border-bottom: 2px solid crimson;
}

@media screen and (max-width: 280px) {
    .header{
        font-size: 2.4rem;
    }
}
.footer-container{
    background: #560bad;
    padding: 2%;
}
.footer-container a{
    text-decoration: none;
    color: rgb(69, 230, 109);
    font-family: 'Indie Flower', cursive;
    font-size: xx-large;
}

@media screen and (max-width: 1024px) {
    .footer-container a{
        font-size: larger;
    }
}
.select{
    color: #2bc076;
    font-size: 2rem;
    text-align: center;
    margin: 2%;
    font-family: 'Indie Flower', monospace;
    font-weight: bolder;
}
.home-container{
    display: flex;
    justify-content: space-around;
    flex-direction: column-reverse;
    align-items: center;
}
.img{
    display: flex;
    justify-content: center;
    margin: 2%;
    opacity: 0.5;
}
.gif{
    border-radius: 50px;
}
.credentials{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.textfield{
    width: 40%;
}
.btn{
    width: 40%;
}
.err{
    width: 40%;
}

@media screen and (max-width: 280px) {
    .select{
        font-size: 1.8rem;
    }
}
@media screen and (max-width: 600px) {
    .textfield{
        width: 95%;
    }
    .btn{
        width: 95%;
    }
    .err{
        width: 95%;
    }
    .gif{
        width: 50%;
    }
}
@media screen and (max-width: 1024px) {
    .textfield{
        width: 90%;
    }
    .btn{
        width: 90%;
    }
    .err{
        width: 90%;
    }
}
.question{
    width: 100%;
}
.current-question{
    padding: 3%;
}
.options{
    width: 100%;
    height: 40px;
}
.right{
    background: green;
}
.wrong{
    background: red;
}
.control{
    width: 50%;
    margin-top: 2%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

@media screen and (max-width: 1024px) {
    .control{
        width: 100%;
    }
}
.name{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
    font-family: 'Abril Fatface', monospace;
    font-size: 2rem;
    letter-spacing: 2px;
}
.quiz-info{
    width: 100%;
}
.current-score{
    display: flex;
    justify-content: space-between;
    margin: 0 10% 0 10%;
    color: cornsilk;
    font-family: 'Zen Tokyo Zoo', monospace;
    font-weight: 500;
    font-size: larger;
}

@media screen and (max-width: 600px) {
    .hi{
        font-size: 1.8rem;
    }
    .current-score{
        margin: 0 6% 0 6%;
        font-size: 1.6rem;
    }
}
@media screen and (max-width: 320px) {
    .hi{
        font-size: 1.5rem;
    }
}
@media screen and (max-width: 280px) {
    .hi{
        font-size: 1.3rem;
    }
}
.resultss {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 60vh;
  text-align: center;
}
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.App{
    background-image: url(/static/media/background.2c5b7852.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
}
