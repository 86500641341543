.select{
    color: #2bc076;
    font-size: 2rem;
    text-align: center;
    margin: 2%;
    font-family: 'Indie Flower', monospace;
    font-weight: bolder;
}
.home-container{
    display: flex;
    justify-content: space-around;
    flex-direction: column-reverse;
    align-items: center;
}
.img{
    display: flex;
    justify-content: center;
    margin: 2%;
    opacity: 0.5;
}
.gif{
    border-radius: 50px;
}
.credentials{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.textfield{
    width: 40%;
}
.btn{
    width: 40%;
}
.err{
    width: 40%;
}

@media screen and (max-width: 280px) {
    .select{
        font-size: 1.8rem;
    }
}
@media screen and (max-width: 600px) {
    .textfield{
        width: 95%;
    }
    .btn{
        width: 95%;
    }
    .err{
        width: 95%;
    }
    .gif{
        width: 50%;
    }
}
@media screen and (max-width: 1024px) {
    .textfield{
        width: 90%;
    }
    .btn{
        width: 90%;
    }
    .err{
        width: 90%;
    }
}