.header{
    text-decoration: none;
    font-size: 3rem;
    text-transform: uppercase;
    color: cornsilk;
    font-family: 'Zen Loop', cursive;
    font-weight: bold;
}
.header-container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2%;
    border-bottom: 2px solid crimson;
}

@media screen and (max-width: 280px) {
    .header{
        font-size: 2.4rem;
    }
}