.question{
    width: 100%;
}
.current-question{
    padding: 3%;
}
.options{
    width: 100%;
    height: 40px;
}
.right{
    background: green;
}
.wrong{
    background: red;
}
.control{
    width: 50%;
    margin-top: 2%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

@media screen and (max-width: 1024px) {
    .control{
        width: 100%;
    }
}